import { FormEvent, useEffect, useState } from 'react'
import { ChatSession } from '../api/chat'
import * as api from '../api'
import Input from '../components/input/Input'
import List from '../components/list/List'
import { useNavigate } from 'react-router-dom'
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'

interface Props {}

const PolicyChat: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [sessions, setSessions] = useState<ChatSession[]>([])
  const [newSessionName, setNewSessionName] = useState('')
  const [referenceDoc, setReferenceDoc] = useState<File>()

  const getSessions = async () => {
    const res = await api.chat.list()
    if (res.data) {
      setSessions(res.data)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getSessions()
    })
  }, [])

  const handleCreateSessionSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!referenceDoc) return
    const res = await api.chat.create(referenceDoc, newSessionName)
    if (res.data) {
      navigate(`/chat/session/${res.data.id}`)
    }
  }

  return (
    <main className="content">
      <h1>Document Chat</h1>

      <h2>Create new chat session</h2>
      <form onSubmit={handleCreateSessionSubmit}>
        <Input id="new-report-name" labelText="Session Name" value={newSessionName} onChange={setNewSessionName} />
        <label htmlFor="reference-file-upload" className="file-input__label">
          <ArrowUpOnSquareIcon />

          {!referenceDoc && (
            <span>
              Drag and drop reference document here.
              <br />
              Or click to browse.
            </span>
          )}
          {!!referenceDoc && (
            <span>
              Selected file: {referenceDoc.name}
              <br />
              Drag and drop, or click to browse, another file.
            </span>
          )}
        </label>
        <input
          id="reference-file-upload"
          type="file"
          className="file-input"
          onChange={(e) => setReferenceDoc(e.target.files ? e.target.files[0] : undefined)}
        />
        <button className="button" disabled={!newSessionName || !referenceDoc}>
          Create
        </button>
      </form>

      <h2 style={{ marginTop: '64px' }}>Select an existing session</h2>
      <List
        items={sessions}
        displayProp="name"
        onSelect={(chatSession) => navigate(`/chat/session/${chatSession.id}`)}
      />
    </main>
  )
}

export default PolicyChat
