import "./ThreeDotsLoadingSpinner.css";

interface ILoadingSpinnerProps {
  className?: string;
}
const ThreeDotsLoadingSpinner: React.FC<ILoadingSpinnerProps> = ({ className }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle className="spinner_b2T7" cx="4" cy="12" r="3" />
      <circle className="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3" />
      <circle className="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3" />
    </svg>
  );
};

export default ThreeDotsLoadingSpinner;