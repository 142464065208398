import { baseMethods } from "."

export type ReportTemplatePlaceholder = {
  id: string
  /** {{placeholderText}} */
  placeholder: string 
}

export type ReportTemplatePlaceholderValue = ReportTemplatePlaceholder & {
  value: string
}

export type Report = {
  id: string
  createdAt: Date
  name: string
  placeholderValues: ReportTemplatePlaceholderValue[]
  fileUrl?: string
  createdByUserId: string
  reportTemplateId: string
  reportTemplate: ReportTemplate
  chatHistory: ChatHistory[]
}

export type ReportTemplate = {
  id: string
  name: string
  fileUrl: string
  placeholders: ReportTemplatePlaceholder[]
  companyId: string
}

export type ChatHistory = {
  type: 'AI' | 'USER'
  message: string
}


export const listTemplates = async () => {
  return baseMethods.get<ReportTemplate[]>({
    path: '/templates'
  })
}

export const create = async (templateId: string, name: string) => {
  return baseMethods.post<Report>({
    path: `/template/${templateId}/report`,
    body: { name },
  })
}

export const update = async (reportId: string, updates: Partial<Report>) => {
  return baseMethods.patch<Report>({
    path: `/report/${reportId}`,
    body: updates
  })
}

export const list = async () => {
  return baseMethods.get<Report[]>({
    path: '/reports'
  })
}

export const get = async ( reportId: string) => {
  return baseMethods.get<Report>({
    path: `/report/${reportId}`,
  })
}

export const generate = async ( reportId: string) => {
  return baseMethods.post<Report>({
    path: `/report/${reportId}/generate-doc`,
    responseType: 'blob',
  })
}

export const uploadReferenceDoc = async (reportId: string, referenceDoc: File) => {
  const formData = new FormData();
  formData.set('file', referenceDoc);
  return baseMethods.post<Report>({
    path: `/report/${reportId}/upload-reference-doc`,
    body: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export type ChatResponse = { updatedReport: Report, updatedChatHistory: ChatHistory }
export const chat = async (reportId: string, message: string) => {
  return baseMethods.post<ChatResponse>({
    path: `/report/${reportId}/chat`,
    body: { 
      message
    },
    skipLoadingToast: true
  })
}