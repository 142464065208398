import { baseMethods } from '.'

export const jwtLocalStorageKey = 'apiKey'
export type UserRoles = 'admin' | 'superuser'
export interface User {
  id: string
  email: string
  company: {
    id: string
    name: string
  }
  name: string
  roles: UserRoles[]
}

export const sendOtp = async (email: string) => {
  return baseMethods.post<{}>({
    path: '/auth/email-otp/send',
    body: { email },
    errorMessage: (_statusCode, code) => {
      if (code === 'SIGNUP_REQUIRED') {
        return {
          header: 'n/a',
          message: 'n/a',
          bypassToast: true,
        }
      }
    },
    loadingMessage: 'Requesting OTP...',
  })
}

export const verifyOtp = async (email: string, otp: string) => {
  return baseMethods.post<{ jwt: string }>({
    path: '/auth/email-otp/verify',
    body: { email, otp },
    loadingMessage: 'Verifying OTP...',
    errorMessage: (_statusCode, code) => {
      if (code === 'INVALID_OTP') {
        return {
          header: 'Invalid OTP',
          message: 'Please check the OTP we sent via email and try again',
        }
      }
    },
  })
}
