import Toast, { IToast } from "./Toast"
import './Toasts.css'

interface Props {
    toasts: IToast[]
    clearToast: (toastId: string) => void
}

const Toasts: React.FC<Props> = ({ toasts, clearToast }) => {
    return (
        <div className="toasts">
            {toasts.map((toast) => {
                return (
                    <Toast
                        key={toast.id || toast.message}
                        id={toast.id}
                        type={toast.type}
                        header={toast.header}
                        message={toast.message}
                        clearAfterMs={toast.clearAfterMs}
                        messageComponent={toast.messageComponent}
                        clearToast={clearToast}
                    />
                )
            })}
        </div>
    )
}

export default Toasts