import { FormEvent, Fragment, useEffect, useRef, useState } from 'react'
import * as api from '../api'
import { ChatSession } from '../api/chat'
import { useParams } from 'react-router-dom'
import ThreeDotsLoadingSpinner from '../components/loading-spinner/ThreeDotsSpinner'
import Input from '../components/input/Input'

interface Props {}

const ViewChatSession: React.FC<Props> = () => {
  const params = useParams()
  const [session, setSession] = useState<ChatSession | undefined>()
  const [newChatMessage, setNewChatMessage] = useState('')
  const [loading, setLoading] = useState(false) 
  const listRef = useRef<HTMLUListElement>(null);

  const getSession = async () => {
    const res = await api.chat.get(params.sessionId!)
    if (res.data) {
      setSession(res.data)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getSession()
    })
  }, [])

  const handleNewChatSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!session) return;

    const updatedSession: ChatSession = {
      ...session,
      chatHistory: [
        ...session.chatHistory,
        {
          type: 'USER',
          message: newChatMessage
        }
      ]
    }
    setSession(updatedSession)
    setLoading(true)
    setNewChatMessage('')

    const res = await api.chat.message(params.sessionId!, newChatMessage)
    if (res.data) {
      setSession(res.data)
      
    }
    
    setLoading(false)
  }

  return (
    <main className="content">
      <h2>Chat Session</h2>
      {!!session && (
        <Fragment>
          <h1>{session.name}</h1>
          <ul className="chat-container" ref={listRef}>
            {session.chatHistory.map((message) => {
              return <li className={`chat__message chat__message--${message.type.toLowerCase()}`}>{message.message}</li>
            })}
            {!!loading && (
              <li>
                <ThreeDotsLoadingSpinner />
              </li>
            )}
          </ul>
          <form className="new-chat-form" onSubmit={handleNewChatSubmit}>
            <Input
              id="new-chat-message"
              labelText="New message"
              value={newChatMessage}
              onChange={setNewChatMessage}
              disabled={loading}
            />
            <button disabled={!newChatMessage} className="button">
              Submit
            </button>
          </form>
        </Fragment>
      )}
    </main>
  )
}

export default ViewChatSession
