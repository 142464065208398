import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { IToast } from './components/toast/Toast';
import AppRouter from './router/AppRouter';
import * as api from './api'
import Toasts from './components/toast/Toasts';
import { User } from './api/auth';

export type ISetToastFn = (toast: IToast) => string
export type IClearToastFn = (toastId: string) => void
export interface IAppContext {
  user: User,
  setToast: ISetToastFn
  clearToast: IClearToastFn
}

const initialContext: IAppContext = {
  user: {
    email: '',  
    id: '',
    name: '',
    roles: [],
    company: {
      id: '',
      name: ''
    }
  },
  setToast: () => uuid(),
  clearToast: () => {}
}

export const AppContext = React.createContext<IAppContext>(initialContext)
interface IAppProps {
  jwt?: string
  user: User
}

const App: React.FC<IAppProps> = ({ jwt, user }) => {
  const [toasts, setToasts] = useState<IToast[]>([])
  const setToast = (toast: IToast) => {
    if (!toast.message && !toast.header) {
      return ''
    }

    const newToastId = uuid()
    

    setToasts((toasts) => {
      return [
        ...toasts,
        {
          id: newToastId,
          ...toast
        }
      ]
    })

    return newToastId
  }

  const clearToast = (toastId: string) => {
    setTimeout(() => {
        setToasts((toasts) => {
          return toasts.filter((toast) => {
            return toastId !== toast.id
          })
        })
    }, 300)
}

  useEffect(() => {
      api.init(setToast, clearToast, jwt)
  }, [jwt])

  const context: IAppContext = {
    setToast,
    user,
    clearToast
  }

  return (
    <AppContext.Provider value={context}>
      <Toasts toasts={toasts} clearToast={clearToast} />
      <AppRouter />
    </AppContext.Provider>
    
  );
}

export default App;
