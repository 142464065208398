import { baseMethods } from "."
import { ChatHistory } from "./reports"

export type ChatSession = {
  id: string
  createdAt: Date
  name: string
  fileUrl: string
  chatHistory: ChatHistory[];
  createdByUserId: string
}

export const list = async () => {
  return baseMethods.get<ChatSession[]>({ 
    path: '/chat/sessions'
  })
}

export const create = async (file: File, name: string) => {
  const formData = new FormData();
  formData.set('file', file);
  formData.set('name', name);
  return baseMethods.post<ChatSession>({
    path: '/chat/session',
    body: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const get = (id: string) => {
  return baseMethods.get<ChatSession>({
    path: `/chat/session/${id}`
  })
}

export const message = (id: string, message: string) => {
  return baseMethods.post<ChatSession>({
    path: `/chat/session/${id}/message`,
    body: { message }
  })
}
