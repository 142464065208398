import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ReportHomePage from '../pages/ReportHomePage'
import Nav from '../components/nav/Nav'
import EditReportPage from '../pages/EditReportPage'
import PolicyChat from '../pages/PolicyChat'
import ViewChatSession from '../pages/ViewChatSession'

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route />
        <Route path="/" element={<PolicyChat />} />
        <Route path="/chat/session/:sessionId" element={<ViewChatSession />} />
        <Route path="/reports" element={<ReportHomePage />} />
        <Route path="/report/:reportId" element={<EditReportPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
