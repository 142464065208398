import { title } from "case";
import { ReportTemplatePlaceholderValue } from "../../api/reports"
import Input from "../input/Input";

interface Props {
  placeholderValue: ReportTemplatePlaceholderValue
  onChange: (placeholderValue: ReportTemplatePlaceholderValue) => void;
}

const PlaceholderInput: React.FC<Props> = ({ placeholderValue, onChange }) => {
  const label = title(placeholderValue.placeholder.replaceAll('{', '').replaceAll('}', ''))

  return (
    <div>
      <Input
        id={`paceholder-input-${placeholderValue.id}`}
        labelText={label}
        type="textarea"
        value={placeholderValue.value}
        onChange={(value) => onChange({...placeholderValue, value})}
      />
    </div>
  )
}

export default PlaceholderInput