import React, { useEffect, useState } from 'react'
import * as api from '../api'
import { jwtLocalStorageKey } from '../api/auth'
import Input from '../components/input/Input'
import { IToast } from '../components/toast/Toast'
import Toasts from '../components/toast/Toasts'
import styles from "./LoginPage.module.css";

interface Props {}

const LoginPage: React.FC<Props> = () => {
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [toast, setToast] = useState<IToast>({})

  const dummySetToastFn = (newToast: IToast) => {
    setToast(newToast)
    return ''
  }

  const dummyClearToastFn = () => {
    setToast({})
  }

  useEffect(() => {
    api.init(dummySetToastFn, dummyClearToastFn)
  }, [])

  const handleSendOtpSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    const res = await api.auth.sendOtp(email)

    if (res.error && res.error.code === 'SIGNUP_REQUIRED') {
      setToast({
        header: 'User not found',
        message: 'No user found with the provided email address, please double check and try again',
        type: 'error',
      })
      return
    }

    if (res.error) {
      setToast({
        header: 'Error',
        message: 'Something went wrong, please try again',
        type: 'error',
      })
      return
    }

    setEmailSent(true)
  }

  const handleVerifyOtpSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    const res = await api.auth.verifyOtp(email, otp)

    if (res.data) {
      window.localStorage.setItem(jwtLocalStorageKey, res.data.jwt)
      window.location.reload()
    }
  }

  return (
    <React.Fragment>
      <Toasts toasts={[toast]} clearToast={() => setToast({})} />
      <div className={`content ${styles['login__container']}`}>
        <h1>Log In</h1>
        {!emailSent && (
          <form action="" onSubmit={handleSendOtpSubmit}>
            <Input
              id="email"
              type="email"
              labelText="Enter your email address"
              helpText="We will send a secure one-time pass code"
              value={email}
              onChange={(val) => setEmail(String(val))}
            />
            <button className="button" disabled={!email}>
              Send OTP
            </button>
          </form>
        )}
        {!!emailSent && (
          <form onSubmit={handleVerifyOtpSubmit}>
            <Input
              id="otp"
              type="text"
              labelText="Enter the OTP sent to your email address"
              helpText="This is valid for 15 minutes"
              value={otp}
              onChange={(val) => setOtp(String(val))}
            />
            <button className="button" disabled={!otp}>
              Submit
            </button>
          </form>
        )}
      </div>
    </React.Fragment>
  )
}

export default LoginPage
