import { Fragment, HTMLAttributes, PropsWithChildren } from 'react';
import styles from './Modal.module.css';
import { XMarkIcon } from '@heroicons/react/24/outline';

interface Props extends PropsWithChildren {
  isOpen: boolean;
  handleClose: () => void;
  modalStyles?: HTMLAttributes<HTMLDivElement>['style'];
}

const Modal: React.FC<Props> = ({ isOpen, children, handleClose, modalStyles }) => {
  return (
    <Fragment>
      <div
        onClick={handleClose}
        className={`${styles['modal-overlay']} ${isOpen ? styles['modal-overlay--show'] : ''}`}
      ></div>
      <div
        className={`${styles['modal-content']} ${isOpen ? styles['modal-content--show'] : ''}`}
        style={modalStyles}
      >
        {!!isOpen && (
          <Fragment>
            <button
              type="button"
              className={`${styles['close-button']} link-button`}
              onClick={handleClose}
            >
              <XMarkIcon style={{ width: '24px' }} />
            </button>
            {children}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default Modal;
