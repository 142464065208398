import { FormEvent, Fragment, useEffect, useRef, useState } from 'react'
import * as api from '../api'
import { useParams } from 'react-router-dom'
import PlaceholderInput from '../components/placeholder-input/PlaceholderInput'
import { Report, ReportTemplatePlaceholderValue } from '../api/reports'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import './EditReportPage.css'
import { ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import Input from '../components/input/Input'
import ThreeDotsLoadingSpinner from '../components/loading-spinner/ThreeDotsSpinner'

interface Props {}

const EditReportPage: React.FC<Props> = () => {
  const params = useParams()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [report, setReport] = useState<Report>()
  const [referenceDoc, setReferenceDoc] = useState<File>()
  const [newChatMessage, setNewChatMessage] = useState('')
  const [loading, setLoading] = useState(false) 
  const listRef = useRef<HTMLUListElement>(null);

  const scrollToBottom = () => {
    if (listRef.current) {
      const scrollHeight = listRef.current.scrollHeight;
      listRef.current.scrollTo({ behavior: "smooth", top: scrollHeight });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [loading, selectedTabIndex]);

  const getReport = async () => {
    const res = await api.report.get(params.reportId!)
    if (res.data) {
      setReport(res.data)

      if (res.data.placeholderValues[0].value) {
        setSelectedTabIndex(1)
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getReport()
    })
  }, [])

  const handleReferenceDocSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!referenceDoc) return
    const res = await api.report.uploadReferenceDoc(params.reportId!, referenceDoc)
    if (res.data) {
      setReport(res.data)
      setSelectedTabIndex(1)
    }
  }

  const handlePlaceholderValueUpdate = (updatedPlaceholderValue: ReportTemplatePlaceholderValue) => {
    setReport((report) => {
      if (!report) return undefined

      return {
        ...report,
        placeholderValues: report.placeholderValues.map((placeholderValue) => {
          if (placeholderValue.id !== updatedPlaceholderValue.id) {
            return placeholderValue
          }

          return updatedPlaceholderValue
        }),
      }
    })
  }

  const handleSave = async () => {
    const res = await api.report.update(params.reportId!, { placeholderValues: report?.placeholderValues })
    if (res.data) {
      setReport(res.data)
    }
  }

  const generateReport = async () => {
    const res = await api.report.generate(params.reportId!)
    if (res.data) {
      const downloadUrl = window.URL.createObjectURL(res.data as any)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${report?.name}.docx`)
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const handleNewChatSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!report) return;

    const updatedReport: Report = {
      ...report,
      chatHistory: [
        ...report.chatHistory,
        {
          type: 'USER',
          message: newChatMessage
        }
      ]
    }
    setReport(updatedReport)
    setLoading(true)
    setNewChatMessage('')

    const res = await api.report.chat(params.reportId!, newChatMessage)
    if (res.data) {
      setReport(res.data.updatedReport)
      
    }
    
    setLoading(false)
  }

  return (
    <main className="content">
      <h2>Build Report</h2>
      {!!report && (
        <Fragment>
          <h1>{report.name}</h1>

          <Tabs selectedIndex={selectedTabIndex} onSelect={setSelectedTabIndex}>
            <TabList>
              <Tab>Upload Reference Doc</Tab>
              <Tab>Report Builder</Tab>
              <Tab>Chat</Tab>
            </TabList>

            <TabPanel>
              <h3>Upload reference doc</h3>
              <form onSubmit={handleReferenceDocSubmit}>
                <label htmlFor="reference-file-upload" className="file-input__label">
                  <ArrowUpOnSquareIcon />

                  {!referenceDoc && (
                    <span>
                      Drag and drop reference document here.
                      <br />
                      Or click to browse.
                    </span>
                  )}
                  {!!referenceDoc && (
                    <span>
                      Selected file: {referenceDoc.name}
                      <br />
                      Drag and drop, or click to browse, another file.
                    </span>
                  )}
                </label>
                <input
                  id="reference-file-upload"
                  type="file"
                  className="file-input"
                  onChange={(e) => setReferenceDoc(e.target.files ? e.target.files[0] : undefined)}
                />
                <button disabled={!referenceDoc} className="button">
                  Upload
                </button>
              </form>
            </TabPanel>
            <TabPanel>
              <div className="finalise__header">
                <h3>Finalise report</h3>
                <button onClick={generateReport} className="button button--save">
                  Generate
                </button>
              </div>

              {report.placeholderValues.map((placeholderValue) => {
                return (
                  <PlaceholderInput
                    key={placeholderValue.id}
                    placeholderValue={placeholderValue}
                    onChange={handlePlaceholderValueUpdate}
                  />
                )
              })}
              <button onClick={handleSave} className="button">
                Save
              </button>
            </TabPanel>
            <TabPanel>
              <h3>Chat with report</h3>
              <ul className="chat-container" ref={listRef}>
                {report.chatHistory.map((message) => {
                  return (
                    <li className={`chat__message chat__message--${message.type.toLowerCase()}`}>{message.message}</li>
                  )
                })}
                {!!loading && <li><ThreeDotsLoadingSpinner /></li>}
              </ul>
              <form className="new-chat-form" onSubmit={handleNewChatSubmit}>
                <Input
                  id="new-chat-message"
                  labelText="New message"
                  value={newChatMessage}
                  onChange={setNewChatMessage}
                  disabled={loading}
                />
                <button disabled={!newChatMessage} className="button">Submit</button>
              </form>
            </TabPanel>
          </Tabs>
        </Fragment>
      )}
    </main>
  )
}

export default EditReportPage
