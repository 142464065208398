import { ArrowRightOnRectangleIcon, HomeIcon } from '@heroicons/react/24/outline'
import { jwtLocalStorageKey } from '../../api/auth'
import styles from './Nav.module.css'
import { Link } from 'react-router-dom'

interface Props {}

const Nav: React.FC<Props> = () => {
  const logout = () => {
    window.localStorage.removeItem(jwtLocalStorageKey)
    window.location.reload()
  }

  return (
    <nav className={`content ${styles['nav']}`}>
      <div className={styles['nav__left']}>
        <a href="/" className={styles['nav__happ-name']}>
          AI Tools
        </a>
      </div>
      <div className={styles['nav__right']}>
        <Link to="/" className={styles['nav__button']}>
          <HomeIcon />
        </Link>
        <button className={styles['nav__button']} title="Logout" onClick={logout}>
            <ArrowRightOnRectangleIcon />
        </button>
      </div>
    </nav>
  )
}

export default Nav
