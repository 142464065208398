import { FormEvent, useContext, useEffect, useState } from 'react'
import * as api from '../api'
import { Report, ReportTemplate } from '../api/reports'
import { Link, useNavigate } from 'react-router-dom'
import Modal from '../components/modal/Modal'
import Input from '../components/input/Input'
import List from '../components/list/List'

interface Props {}

const HomePage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [templates, setTemplates] = useState<ReportTemplate[]>([])
  const [reports, setReports] = useState<Report[]>([])
  const [selectedTemplate, setSelectedTemplate] = useState<ReportTemplate>()
  const [newReportName, setNewReportName] = useState('')

  const getTemplates = async () => {
    const res = await api.report.listTemplates()
    if (res.data) {
      setTemplates(res.data)
    }
  }

  const getReports = async () => {
    const res = await api.report.list()
    if (res.data) {
      setReports(res.data)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      getTemplates()
      getReports()
    })
  }, [])

  const handleCreateReportFormSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!selectedTemplate) return
    const res = await api.report.create(selectedTemplate.id, newReportName)
    if (res.data) {
      navigate(`/report/${res.data.id}`)
    }
  }

  return (
    <main className="content">
      <h1>Report builder</h1>

      <h2>Create new report</h2>
      <p>Select a template:</p>
      <List items={templates} displayProp="name" onSelect={setSelectedTemplate} />

      <h2 style={{ marginTop: '64px' }}>Select an existing report</h2>
      <List items={reports} displayProp="name" onSelect={(report) => navigate(`/report/${report.id}`)} />
      <Modal isOpen={!!selectedTemplate} handleClose={() => setSelectedTemplate(undefined)}>
        <h3>Create new report</h3>
        <form onSubmit={handleCreateReportFormSubmit}>
          <Input id="new-report-name" labelText="Report Name" value={newReportName} onChange={setNewReportName} />
          <button className="button" disabled={!newReportName}>
            Create
          </button>
        </form>
      </Modal>
    </main>
  )
}

export default HomePage
