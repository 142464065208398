import { HTMLAttributes } from 'react';
import './List.css'

interface Props {
  items: any[]
  onSelect: (item: any) => void;
  displayProp: string
  styles?: HTMLAttributes<HTMLUListElement>['style']
}

const List: React.FC<Props> = ({ items, displayProp, onSelect, styles }) => {

  return (
    <ul className='list' style={styles}>
      {items.map((item) => {
        return (
          <li onClick={() => onSelect(item)} className='list__item'>{item[displayProp]}</li>
        )
      })}
    </ul>
  )
}

export default List