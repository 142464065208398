import { ReactElement, useEffect, useState } from "react"
import LoadingSpinner from "../loading-spinner/LoadingSpinner"
import './Toast.css'

export type IToastType = 'info' | 'loading' | 'error' | 'success'

export interface IToast {
    id?: string
    type?: IToastType
    header?: string
    message?: string
    messageComponent?: ReactElement
    clearAfterMs?: number,
}

export interface IToastProps extends IToast{
    clearToast: (toastId: string) => void
}

const Toast: React.FC<IToastProps> = ({
    id,
    type = 'info',
    header,
    message,
    clearAfterMs,
    messageComponent,
    clearToast,
}) => {
    const [showToast, setShowToast] = useState(false)

    useEffect(() => {
        const hasMessage = !!message || !!header
        if (hasMessage && type) {
            setShowToast(true)
        } else {
            setShowToast(false)
            clearToast(id || '')
        }
    }, [header, message, type])
    
    useEffect(() => {
        if (clearAfterMs) {
            setTimeout(() => {
                setShowToast(false)
                clearToast(id || '')
            }, clearAfterMs)
        }
    }, [clearAfterMs])

    return (
        <div className={`toast toast--${type}${showToast ? ' toast--show' : ''}`}>
            <div className="toast__inner">
                <div className="toast__text">
                    {!!header && <p className="toast__header">{header}</p>}
                    {!!messageComponent && (
                        <div className="toast__message">{messageComponent}</div>
                    )}
                    {!messageComponent && (
                        <p className="toast__message">{message}</p>
                    )}
                </div>
                {type === 'loading' && <LoadingSpinner className="toast__spinner" />}
            </div>
            
        </div>
    )
}

export default Toast
